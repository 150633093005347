export class EventDispatcher {
  constructor() {
    this.__listeners = {}
  }

  on = (type, callback) => {
    if (!this.__listeners[type]) {
      this.__listeners[type] = []
    }
    this.__listeners[type].push(callback)
  }

  off = (type, callback) => {
    const arr = this.__listeners[type]
    if (arr) {
      let index = arr.indexOf(callback)
      if (index !== -1) {
        arr.splice(index, 1)
      }
    }
  }

  dispatch = (type, data) => {
    if (this.__listeners[type]) {
      this.__listeners[type].forEach(callback => callback(data))
    }
  }
  
}

export const EventBus = new EventDispatcher()