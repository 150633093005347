import { PerspectiveCamera, Scene } from "three"
import { Screen } from "./global/Screen"
import { RenderingPipeline } from "./post/RenderingPipeline"
import { provide as provideUniform } from "./global/Uniforms";
import { CameraController } from "./components/CameraController";
import { SuperFormulaGL } from "./components/superFormulaGL";
import { EventBus } from "../EventDispatcher";
import { Background } from "./components/Background";

export class Facade {
  static screen = new Screen()
  static renderPipe = new RenderingPipeline()
  static camera = new PerspectiveCamera(45, Facade.screen.aspect, .1, 1e3)
  static scene = new Scene()
  static components = []

  create = ({ container }) => {

    Facade.container = container
    Facade.screen.setContainer(container)
    Facade.renderPipe.setContainer(container)
    
    Facade.components.push(
      new CameraController(),
      new Background(),
      new SuperFormulaGL(),
    )
    
  }

  onFrame = time => {
    if (!('prevTime' in this)) {
      this.prevTime = time
    }
    const dt = Math.min(1e3 / 30, time - this.prevTime)
    const ds = dt * 1e-3
    const seconds = time * 1e-3
    this.prevTime = time

    provideUniform('time', time)
    provideUniform('seconds', seconds)

    EventBus.dispatch('frame', { time, dt, seconds, ds })
    
    Facade.renderPipe.render()

    EventBus.dispatch('rendered', { time, dt, seconds, ds })
  }
}