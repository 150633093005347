import { EventBus } from "../../EventDispatcher";
import { Facade } from "../Facade";

export class CameraController {
  constructor() {

    this.onResize(Facade.screen)

    EventBus.on('resize', this.onResize)
    EventBus.on('camera.update', this.updateCamera)
  }

  onResize = ({ aspect }) => {
    Facade.camera.aspect = aspect
    Facade.camera.updateProjectionMatrix()
  }

  updateCamera = ({ x, y, z, fov, target }) => {
    Facade.camera.position.set(x, y, z)
    Facade.camera.fov = fov
    const { x: tx, y: ty, z: tz } = target
    Facade.camera.lookAt(tx, ty, tz)
    Facade.camera.updateMatrix()
    Facade.camera.updateProjectionMatrix()
  }
}