import { EventBus } from "../../EventDispatcher"
import { Facade } from "../../gl/Facade"
import { SuperFormulaGL } from "../../gl/components/superFormulaGL"
import { bind } from "../../gl/global/Uniforms"
import { DoubleSide, Mesh, MeshStandardMaterial, Scene } from "three"
import { GLTFExporter } from "./GLTFExporter"

export class MeshExporter {
  constructor() {
    
    this.link = document.createElement('a')
    this.link.style.display = 'none'
    document.body.appendChild(this.link)
    
    EventBus.dispatch('controls.button.add', {
      label: 'Export GLTF',
      event: 'export.gltf',
    })

    EventBus.on('export.gltf', this.onExportGLTF)
  }

  save = (blob, filename) => {
    this.link.href = URL.createObjectURL(blob)
    this.link.download = filename
    this.link.click()
  }

  saveArrayBuffer = (buffer, filename) =>
    this.save(new Blob([buffer], { type: 'application/octet-stream' }), filename)

  saveString = (text, filename) =>
    this.save(new Blob([text], { type: 'text/plain' }), filename)

  sf = (phi, a, b, m, n1, n2, n3) => 
    Math.pow(
      Math.pow(Math.abs(Math.cos(m * phi * .25) / a), n2) +
      Math.pow(Math.abs(Math.sin(m * phi * .25) / b), n3),
      -1 / n1
    )
  
  onExportGLTF = () => {
    const sfgl = Facade.components.find(component => component instanceof SuperFormulaGL),
          geometry = sfgl.mesh.geometry.clone(),
          positions = geometry.attributes.position,
          arr = positions.array

    const latR = bind('latR').value,
          latA = bind('latA').value,
          latB = bind('latB').value,
          latN1 = bind('latN1').value,
          latN2 = bind('latN2').value,
          latN3 = bind('latN3').value,
          latM = bind('latM').value,
          lonR = bind('lonR').value,
          lonA = bind('lonA').value,
          lonB = bind('lonB').value,
          lonN1 = bind('lonN1').value,
          lonN2 = bind('lonN2').value,
          lonN3 = bind('lonN3').value,
          lonM = bind('lonM').value

    for (let i = 0; i < arr.length; i += positions.itemSize) {
      const x = arr[i], y = arr[i + 1], z = arr[i + 2],
            lon = Math.atan2(x, z), lat = Math.asin(y),
            rLon = lonR * this.sf(lon, lonA, lonB, lonM, lonN1, lonN2, lonN3),
            rLat = latR * this.sf(lat, latA, latB, latM, latN1, latN2, latN3)

      arr[i] = rLon * Math.cos(lon) * rLat * Math.cos(lat)
      arr[i + 1] = rLat * y
      arr[i + 2] = rLon * Math.sin(lon) * rLat * Math.cos(lat)
    }

    geometry.computeVertexNormals()

    const scene = new Scene()
    scene.add(
      new Mesh(geometry,
        new MeshStandardMaterial({
          side: DoubleSide,
        })
      )
    )

    const gltfExporter = new GLTFExporter()
    gltfExporter.parse(
      scene,
      result => {
        if ( result instanceof ArrayBuffer ) {
          this.saveArrayBuffer(result, 'lmnop.glb')
        } else {
          const output = JSON.stringify(result, null, 2)
          this.saveString(output, 'lmnop.gltf')
        }
      },
      error => console.error('An error happened during parsing', error),
      {
        trs: true,
        onlyVisible: true,
        truncateDrawRange: true,
        binary: true,
        maxTextureSize: 256,
      }
    )
  }
}