import { getProject, types } from "@theatre/core"
import { EventBus } from "../EventDispatcher"

export class StudioWrap {

  constructor(state = undefined) {

    this.project = getProject('SuperFormula', { state })
    this.sheet = this.project.sheet('Sheet')
    this.components = this.initComponents(this.sheet)

    const { shape, camera, background, shader } = this.components
    
    shape.onValuesChange(val => EventBus.dispatch('shape.update', val))
    camera.onValuesChange(val => EventBus.dispatch('camera.update', val))
    background.onValuesChange(val => EventBus.dispatch('background.update', val))
    shader.onValuesChange(val => EventBus.dispatch('shading.update', val))

  }

  initComponents = (sheet) => {
    
    const shape = sheet.object('Shape', {
      latitudeR: types.number(1, { range: [.1, 5] }),
      latitudeA: types.number(1, { range: [0, 2] }),
      latitudeB: types.number(1, { range: [0, 2] }),
      latitudeM: types.number(4, { range: [1, 20] }),
      latitudeN1: types.number(1, { range: [.1, 20] }),
      latitudeN2: types.number(1, { range: [.1, 20] }),
      latitudeN3: types.number(1, { range: [.1, 20] }),
    
      longitudeR: types.number(1, { range: [.1, 5] }),
      longitudeA: types.number(1, { range: [0, 2] }),
      longitudeB: types.number(1, { range: [0, 2] }),
      longitudeM: types.number(4, { range: [1, 20] }),
      longitudeN1: types.number(1, { range: [.1, 20] }),
      longitudeN2: types.number(1, { range: [.1, 20] }),
      longitudeN3: types.number(1, { range: [.1, 20] }),
    })

    const camera = sheet.object('Camera', {
      x: 3,
      y: 3,
      z: 3,
      fov: types.number(45, { range: [1, 179] }),
      target: types.compound({
        x: 0,
        y: 0,
        z: 0,
      }, {
        label: 'Target'
      })
    })
    const background = sheet.object('Background', {
      center: types.compound({
        r: types.number(40, { range: [0, 255], nudgeMultiplier: 1 }),
        g: types.number(0, { range: [0, 255], nudgeMultiplier: 1 }),
        b: types.number(20, { range: [0, 255], nudgeMultiplier: 1 }),
      }, {
        label: 'Center'
      }),
      edge: types.compound({
        r: types.number(20, { range: [0, 255], nudgeMultiplier: 1 }),
        g: types.number(0, { range: [0, 255], nudgeMultiplier: 1 }),
        b: types.number(40, { range: [0, 255], nudgeMultiplier: 1 }),
      }, {
        label: 'Edges'
      }),
      power: types.number(2, { range: [.1, 10] })
    })

    const shader = sheet.object('Shader', {
      type: types.stringLiteral('neon', {
        neon: 'Neon',
        pos: 'Positional',
        posCube: 'Positional Cubic',
        greyBands: 'Grey Bands',
      }),
      stripes: false,
    })

    return {
      shape,
      camera,
      background,
      shader,
    }
  }
}