import { Facade } from "./gl/Facade";
import { ConrolButtons } from "./components/ControlButtons";
import { Recorder } from "./components/Recorder";
import { MeshExporter } from "./components/MeshExporter";
import { RandomizeShape } from "./components/RandomizeShape";
import { StudioWrap } from "./components/StudioWrap";
import studio from '@theatre/studio'

function init(state) {

  document.getElementById('init').remove()

  const gl = new Facade()
  
  gl.create({
    container: document.getElementById('container')
  })

  studio.initialize()

  const wrap = new StudioWrap(state)
  wrap.project.ready.then(() => {
    
    new ConrolButtons()
    new MeshExporter()
    // new RandomizeShape(shape)
    const recorder = new Recorder(wrap.sheet.sequence)
    
    const onFrame = t => {

      const time = recorder.getTime(t)
      gl.onFrame(time)

      requestAnimationFrame(onFrame)
    }
    
    requestAnimationFrame(onFrame)

  })

}

const dropzone = document.body
let alreadyDropLoaded = false

dropzone.addEventListener('drop', (e) => {
  if (alreadyDropLoaded) {
    return
  }
  
  alreadyDropLoaded = true
  
  e.preventDefault()

  localStorage.clear() // theatre api is so fckd up

  const file = e.dataTransfer.files[0]
  const reader = new FileReader()
  reader.onloadend = () => {
    init(JSON.parse(reader.result))
  }
  reader.readAsText(file)
})

dropzone.addEventListener('dragover', (e) => {
  e.preventDefault()
})

document.querySelector('#init .btn').addEventListener('click', () => {
  if (alreadyDropLoaded) {
    return
  }

  init()
})