import { DoubleSide, Mesh, MeshBasicMaterial, ShaderMaterial, SphereBufferGeometry } from "three";
import { EventBus } from "../../../EventDispatcher";
import { Facade } from "../../Facade";

import { bind, provide } from "../../global/Uniforms";
import vertexShader from './vertexShader.glsl'
import fragmentShader from './fragmentShader.glsl'

import neon from './neon.glsl'
import positional from './positional.glsl'
import positionalCubic from './positionalCubic.glsl'
import greyBands from './greyBands.glsl'

export class SuperFormulaGL {
  constructor() {
    this.mesh = new Mesh(
      new SphereBufferGeometry(1, 512, 256),
      new MeshBasicMaterial()
    )

    this.materials = {
      pos: positional,
      posCube: positionalCubic,
      neon,
      greyBands,
    }

    Facade.scene.add(this.mesh)

    EventBus.on('shape.update', this.onUpdate)
    EventBus.on('shading.update', this.changeMaterial)
  }

  onUpdate = ({
    latitudeR, latitudeA, latitudeB, latitudeN1, latitudeN2, latitudeN3, latitudeM,
    longitudeR, longitudeA, longitudeB, longitudeN1, longitudeN2, longitudeN3, longitudeM
  }) => {
    provide('latR', latitudeR)
    provide('latA', latitudeA)
    provide('latB', latitudeB)
    provide('latN1', latitudeN1)
    provide('latN2', latitudeN2)
    provide('latN3', latitudeN3)
    provide('latM', latitudeM)
    provide('lonR', longitudeR)
    provide('lonA', longitudeA)
    provide('lonB', longitudeB)
    provide('lonN1', longitudeN1)
    provide('lonN2', longitudeN2)
    provide('lonN3', longitudeN3)
    provide('lonM', longitudeM)
  }

  changeMaterial = ({ type, stripes }) => {
    this.mesh.material && this.mesh.material.dispose()

    const fs = type in this.materials ? this.materials[type] : neon

    this.mesh.material = new ShaderMaterial({
      uniforms: {
        latR: bind('latR'),
        latA: bind('latA'),
        latB: bind('latB'),
        latN1: bind('latN1'),
        latN2: bind('latN2'),
        latN3: bind('latN3'),
        latM: bind('latM'),
        lonR: bind('lonR'),
        lonA: bind('lonA'),
        lonB: bind('lonB'),
        lonN1: bind('lonN1'),
        lonN2: bind('lonN2'),
        lonN3: bind('lonN3'),
        lonM: bind('lonM'),
      },
      side: DoubleSide,
      vertexShader,
      fragmentShader: fragmentShader.replace(
        'inject_stripes;',
        stripes ? 'if (step(.5, fract(vUv.y * 20.)) == 0.) discard;' : ''
      ).replace(
        'inject_color;',
        fs
      ),
    })
  }
}