import { WebGLRenderer } from "three"
import { EventBus } from "../../EventDispatcher"
import { Facade } from "../Facade"

export class RenderingPipeline {

  constructor() {
    this.renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: 'high-performance',
      stencil: false,
    })

    EventBus.on('resize', this.onResize)
  }

  setContainer = container => {
    container.appendChild(this.renderer.domElement)
    
    this.onResize(Facade.screen)
  }

  render = () => {
    const { scene, camera } = Facade
    
    this.renderer.setRenderTarget(null)
    this.renderer.render(scene, camera)
  }

  onResize = ({ x, y, dpr }) => {
    this.renderer.setSize(x, y)
    this.renderer.setPixelRatio(dpr)
  }
}