const uniforms = {}

export const bind = name => {
  if (!(name in uniforms)) {
    uniforms[name] = { value: 0 }
  }
  return uniforms[name]
}

export const provide = (name, value) => {
  if (name in uniforms) {
    uniforms[name].value = value
  } else {
    uniforms[name] = { value }
  }
}