import { EventBus } from "../../EventDispatcher";
import { Facade } from "../../gl/Facade";
import ccaptureScript from './ccapture.raw'

export class Recorder {
  constructor(sequence) {

    // yes crutches
    this.lolCrutch = requestAnimationFrame
    
    // even more infernal
    const script = document.createElement('script')
    script.setAttribute('type', 'application/javascript')
    script.innerText = ccaptureScript
    document.body.appendChild(script)
    // ...and some js in static folder ♥

    this.sequence = sequence
    this.controlButtonsAdded = false
    this.isCapturing = false
    this.skipFirstFrame = true
    this.frame = 0
    this.fps = 60
    this.duration = 5

    EventBus.on('record.gif', this.onRecord)
    EventBus.on('record.video', () => this.onRecord(false))

    EventBus.on('rendered', this.onRendered)
  }

  addControlButtons = () => {
    EventBus.dispatch('controls.button.add', {
      label: 'Record GIF',
      event: 'record.gif',
    })

    EventBus.dispatch('controls.button.add', {
      label: 'Record Video',
      event: 'record.video',
    })

    this.controlButtonsAdded = true
  }

  onRecord = (isGif = true) => {
    if (this.isCapturing) {
      return
    }
    this.fps = isGif ? 30 : 60

    EventBus.dispatch('dpr.set', isGif ? .5 : 2)

    this.capturer = new window.CCapture({
      name: 'lmnop',
      framerate: this.fps,
      format: isGif ? 'gif' : 'webm',
      workersPath: '/js/',
    })
    this.capturer.start()
    
    requestAnimationFrame = this.lolCrutch
    
    this.frame = 0
    this.skipFirstFrame = true
    this.isCapturing = true
  }

  onRendered = () => {
    if (!this.controlButtonsAdded && 'CCapture' in window) {
      this.addControlButtons()
    }
    if (this.isCapturing && this.frame < this.duration * this.fps) {

      if (this.skipFirstFrame) {
        this.skipFirstFrame = false
        return
      }

      this.capturer.capture(Facade.renderPipe.renderer.domElement)

      this.frame++
      
      if (this.frame >= this.duration * this.fps) {
        this.capturer.stop()
        this.capturer.save()

        EventBus.dispatch('dpr.reset')

        this.isCapturing = false
      }
    }
  }

  getTime = time => {
    if (this.isCapturing) {
      if (this.sequence) {
        this.sequence.pause()
        this.sequence.position = this.frame / this.fps
      }
      return this.frame / this.fps
    } else {
      return time
    }
  }
}