import { Color, Mesh, PlaneBufferGeometry, ShaderMaterial, WebGLRenderTarget } from "three";
import { EventBus } from "../../EventDispatcher";
import { Facade } from "../Facade";
import { bind, provide } from "../global/Uniforms";

export class Background {
  constructor() {
    this.colorCenter = new Color()
    this.colorEdge = new Color()

    this.mesh = new Mesh(
      new PlaneBufferGeometry(1, 1, 1, 1),
      new ShaderMaterial({
        uniforms: {
          center: { value: this.colorCenter },
          edge: { value: this.colorEdge },
          power: bind('bg.power'),
        },
        vertexShader: /*glsl*/`
          varying vec2 fc;
          void main() {
            fc = uv * 2. - 1.;
            gl_Position = vec4(fc, 0., 1.);
            fc *= ${ Math.SQRT1_2 };
          }
        `,
        fragmentShader: /*glsl*/`
          varying vec2 fc;
          uniform vec3 center;
          uniform vec3 edge;
          uniform float power;
          void main() { 
            float d = pow(length(fc), power);
            gl_FragColor = vec4(mix(center, edge, d), 1.);
          }
        `
      })
    )

    this.renderTarget = new WebGLRenderTarget(1024, 1024)

    Facade.scene.background = this.renderTarget.texture

    EventBus.on('background.update', this.onUpdate)
  }

  onUpdate = ({ center, edge, power }) => {
    this.colorCenter.setRGB(center.r / 255, center.g / 255, center.b / 255)
    this.colorEdge.setRGB(edge.r / 255, edge.g / 255, edge.b / 255)

    provide('bg.power', power)

    Facade.renderPipe.renderer.setRenderTarget(this.renderTarget)
    Facade.renderPipe.renderer.render(this.mesh, Facade.camera)
  }
}