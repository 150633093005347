import { EventBus } from "../EventDispatcher";

export class ConrolButtons {
  constructor() {
    const css = `
.controls-container {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 1000;
}

.controls-container .button {
  border: 1px solid #fff;
  color: #fff;
  font-family: sans-serif;
  margin: 12px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  transition: background .25s, color .25s;
}
.controls-container .button:hover {
  background: #fff;
  color: #000;
}`
    const style = document.createElement('style')
    style.innerText = css
    document.body.appendChild(style)
    
    this.container = document.createElement('div')
    this.container.classList.add('controls-container')
    document.body.appendChild(this.container)

    EventBus.on('controls.button.add', this.addButton)
  }

  addButton = ({ label, event }) => {
    const button = document.createElement('div')
    button.classList.add('button')
    button.innerHTML = label
    button.addEventListener('click', () => {
      EventBus.dispatch(event)
    })
    
    this.container.appendChild(button)
  }
}